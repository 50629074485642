<template>
    <data-table
        :data="dataset"
        :loading="loading"
        :buttons="buttons"
        :header="header"
        :responsive="true"
        class="table-sm-font"
        :auto-update="false"
        :lang="lang"
        :paging="true"
        :actions="true"
        @action="onAction"
    />
</template>

<script>
import DataTableBase from '../../../../../../../Components/DataTableBase.vue'

export default {
    name: 'BankAccountsTable',
    extends: DataTableBase,
    computed: {
        header () {
            return [
                {
                    text: this.$t('bankAccounts.list.name'),
                    data: 'name',
                    filterable: true,
                    sortable: true
                },
                {
                    text: this.$t('bankAccounts.list.accountNumber'),
                    data: 'wholeAccountNumber',
                    filterable: true,
                    sortable: true
                },
                {
                    text: this.$t('bankAccounts.list.iban'),
                    data: 'iban',
                    filterable: true,
                    sortable: true
                },
                {
                    text: this.$t('bankAccounts.list.swift'),
                    data: 'swift',
                    filterable: true,
                    sortable: true
                },
                {
                    text: this.$t('bankAccounts.list.currency'),
                    data: 'currency',
                    filterable: true,
                    sortable: true
                }
            ]
        },
        buttons () {
            return [
                {
                    event: 'show',
                    text: this.$t('dataTable.show'),
                    variant: 'primary'
                }
            ]
        }
    }
}
</script>

<template>
    <div>
        <h4 class="mb-2">{{ $t('clientSessions.detail.ibAndVop.heading') }}</h4>
        <detail-item
            :title="$t('clientSessions.detail.legalInfo.ibAndVop.vop')"
            :content="legalInfo.vop ? $t('app.yes') : $t('app.no')"
        />
        <detail-item
            :title="$t('clientSessions.detail.legalInfo.ibAndVop.ib')"
            :content="ib ? $t('app.yes') : $t('app.no')"
        />
    </div>
</template>
<script>
export default {
    name: 'LegalInfoIBAndVOP',
    props: {
        legalInfo: {
            type: Object,
            required: true
        },
        ib: {
            type: Boolean,
            required: true
        }
    }
}
</script>

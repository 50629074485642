<template>
    <div>
        <h4 class="mb-1">{{$t('personalId.heading')}}</h4>
        <div class="row mb-lg-2">
            <detail-item
                class="col-12 col-lg-6 col-xl-4"
                :title="$t('personalId.birthCity')"
                :content="personalId.birthCity"
            />
            <detail-item
                class="col-12 col-lg-6 col-xl-4"
                :title="$t('personalId.citizenship')"
                :content="$t(`countries.${personalId.citizenship}`)"
            />
            <detail-item
                class="col-12 col-lg-6 col-xl-4"
                :title="$t('personalId.sex')"
                :content="$t(`personalId.${personalId.sex}`)"
            />
            <detail-item
                class="col-12 col-lg-6 col-xl-4"
                :title="$t('personalId.documentNumber')"
                :content="personalId.documentNumber"
            />
            <detail-item
                class="col-12 col-lg-6 col-xl-4"
                :title="$t('personalId.documentValidity')"
                :content="new Date(personalId.documentValidity).toLocaleDateString()"
            />
            <detail-item
                class="col-12 col-lg-6 col-xl-4"
                :title="$t('personalId.documentIssuedBy')"
                :content="personalId.documentIssuedBy"
            />
            <detail-item
                class="col-12 col-lg-6 col-xl-4"
                :title="$t('personalId.documentType')"
                :content="$t(`personalIdTypes.${personalId.documentType}`)"
            />
            <detail-item
                v-if="personalId.secondaryDocumentType"
                class="col-12 col-lg-6 col-xl-4"
                :title="$t('personalId.secondaryDocumentType')"
                :content="$t(`personalIdTypes.${personalId.secondaryDocumentType}`)"
            />
        </div>

        <uploads-list
            :uploads="personalIdUploads"
        />
    </div>
</template>
<script>
import UploadsList from './UploadsList.vue'
export default {
    name: 'PersonalId',
    props: {
        personalId: {
            type: Object,
            required: true
        },
        uploads: {
            type: Array,
            required: true
        }
    },
    components: {
        UploadsList
    },
    computed: {
        personalIdUploads () {
            return this.uploads.filter(upload => (this.personalId?.uploads ?? []).map(item => (item?.upload ?? item)).includes(upload._id))
        }
    }
}
</script>

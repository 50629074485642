<template>
    <div>
        <div class="row">
            <detail-item
                class="col-12 col-lg-6"
                :title="$t('bankAccounts.form.name')"
                :content="bankAccount.name"
            />
            <detail-item
                class="col-12 col-lg-6"
                :title="$t('bankAccounts.form.currency')"
                :content="bankAccount.currency"
            />
        </div>
        <div
            class="row"
            v-if="bankAccount.isForeign"
        >
            <detail-item
                class="col-12 col-lg-6"
                :title="$t('bankAccounts.form.iban')"
                :content="bankAccount.iban"
            />
            <detail-item
                class="col-12 col-lg-6"
                :title="$t('bankAccounts.form.swift')"
                :content="bankAccount.swift"
            />
        </div>
        <div v-else>
            <detail-item
                :title="$t('bankAccounts.form.accountNumber')"
                :content="`${bankAccount.accountNumberPrefix}-${bankAccount.accountNumber}/${bankAccount.bankCode}`"
            />
        </div>
        <div class="mt-4">
            <uploads-list
                v-if="uploads.length > 0"
                :uploads="uploads || []"
            />
        </div>
    </div>
</template>

<script>
import UploadsList from './UploadsList.vue'
export default {
    name: 'BankAccount',
    components: {
        UploadsList
    },
    props: {
        bankAccount: {
            type: Object,
            required: true
        },
        uploads: {
            type: Array,
            required: true
        }
    }
}
</script>

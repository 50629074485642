<template>
    <data-table
        :data="dataset"
        :loading="loading"
        :buttons="buttons"
        :header="header"
        :responsive="true"
        class="table-sm-font"
        :auto-update="false"
        :lang="lang"
        :paging="true"
        :actions="true"
        @action="onAction"
    />
</template>

<script>
import DataTableBase from '../../../../../../../Components/DataTableBase.vue'

export default {
    name: 'CompanyPeopleTable',
    extends: DataTableBase,
    computed: {
        header () {
            return [
                {
                    text: this.$t('clientSessions.detail.person.table.name'),
                    data: 'name',
                    filterable: true,
                    sortable: true
                },
                {
                    text: this.$t('clientSessions.detail.person.table.surname'),
                    data: 'surname',
                    filterable: true,
                    sortable: true
                },
                {
                    text: this.$t('clientSessions.detail.person.table.isExecutive'),
                    data: 'executive',
                    filterable: true,
                    sortable: true,
                    format: (input) => {
                        return input ? this.$t('app.yes') : this.$t('app.no')
                    }
                },
                {
                    text: this.$t('clientSessions.detail.person.table.birtDate'),
                    data: 'birthDate',
                    filterable: true,
                    sortable: true,
                    format (input) {
                        return (input) ? new Date(input).toLocaleDateString() : input
                    }
                },
                {
                    text: this.$t('clientSessions.detail.person.table.function'),
                    data: 'jobFunction',
                    filterable: true,
                    sortable: true
                }
            ]
        },
        buttons () {
            return [
                {
                    event: 'show',
                    text: this.$t('dataTable.show'),
                    variant: 'primary'
                }
            ]
        }
    }
}
</script>

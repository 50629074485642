<template>
    <b-modal
        id="companyPersonModal"
        size="xl"
        hide-footer
        >
        <template #modal-title>
            {{ $t('clientSessions.detail.companyPerson.modal.heading') }}
        </template>
        <company-person
            :company-person="companyPerson"
            :uploads="personUploads"
        />
    </b-modal>
</template>

<script>
import CompanyPerson from './CompanyPerson.vue'

export default {
    name: 'CompanyPersonModal',
    props: {
        companyPerson: {
            type: Object,
            required: true
        },
        uploads: {
            type: Array,
            required: true
        }
    },
    components: {
        CompanyPerson
    },
    computed: {
        personUploads () {
            if (this.companyPerson && Array.isArray(this.companyPerson.uploads) && this.companyPerson.uploads.length > 0) {
                return this.uploads.filter(item => this.companyPerson.uploads.map(item => item.upload).includes(item._id))
            }
            return []
        }
    }
}
</script>

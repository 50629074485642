<template>
    <b-modal
        id="bankAccountModal"
        size="lg"
        hide-footer
        >
        <template #modal-title>
            {{ $t('clientSessions.detail.bankAccount.modal.heading') }}
        </template>
        <bank-account
            :bank-account="bankAccount"
            :uploads="accountUploads"
        />
    </b-modal>
</template>

<script>
import BankAccount from './BankAccount.vue'

export default {
    name: 'BankAccountModal',
    props: {
        bankAccount: {
            type: Object,
            required: true
        },
        uploads: {
            type: Array,
            required: true
        }
    },
    components: {
        BankAccount
    },
    computed: {
        accountUploads () {
            if (this.bankAccount && Array.isArray(this.bankAccount.uploads) && this.bankAccount.uploads.length > 0) {
                return this.uploads.filter(item => this.bankAccount.uploads.includes(item._id))
            }
            return []
        }
    }
}
</script>

<template>
    <div>
        <h4 class="mb-2">{{ $t('clientSessions.detail.clientInfo.personalInfo') }}</h4>
        <detail-item
            :title="$t('clientSessions.detail.companyPerson.isExecutive')"
            :content="companyPerson.executive ? $t('app.yes') : $t('app.no')"
        />
        <div class="row mb-2">
            <detail-item
                class="col-12 col-lg-4"
                :title="$t('clientSessions.detail.companyPerson.fullName')"
                :content="`${companyPerson.titleBefore || ''} ${companyPerson.name} ${companyPerson.surname} ${companyPerson.titleAfter || ''}`"
            />
            <detail-item
                class="col-12 col-lg-4"
                :title="$t('basicData.rc')"
                :content="companyPerson.noRc ? $t('clientSessions.detail.clientInfo.noRc') : companyPerson.rc"
            />
            <detail-item
                class="col-12 col-lg-4"
                :title="$t('basicData.birthDate')"
                :content="new Date(companyPerson.birthDate).toLocaleDateString()"
            />
            <detail-item
                class="col-12 col-lg-4"
                :title="$t('companyOwnersForm.birthCity')"
                :content="companyPerson.birthCity"
            />
            <detail-item
                class="col-12 col-lg-4"
                :title="$t('companyOwnersForm.citizenship')"
                :content="$t(`countries.${companyPerson.citizenship}`)"
            />
            <detail-item
                class="col-12 col-lg-4"
                :title="$t('companyOwnersForm.sex')"
                :content="$t(`personalId.${companyPerson.sex}`)"
            />
            <detail-item
                class="col-12 col-lg-4"
                :title="$t('basicData.countryOrigin')"
                :content="companyPerson.countryOrigin"
            />
        </div>

        <h4 class="mb-2">{{ $t('clientSessions.detail.address.heading') }}</h4>
        <detail-item
            class="mb-4"
            :title="$t('clientSessions.detail.address.fullAddress')"
            :content="`${companyPerson.addressStreet}, ${companyPerson.addressCity}, ${companyPerson.addressZip}, ${$t(`countries.${companyPerson.addressCountry}`)}`"
        />
        <h4 class="mb-2">{{ $t('basicData.headings.otherInfo') }}</h4>
        <div class="row mb-4">
            <detail-item
                class="col-12 col-lg-4"
                :title="$t('basicData.email')"
                :content="companyPerson.email"
            />
            <detail-item
                class="col-12 col-lg-4"
                :title="$t('basicData.phone')"
                :content="companyPerson.phone"
            />
        </div>
        <h4 class="mb-2">{{ $t('companyOwnersForm.documentHeading') }}</h4>
        <div class="row mb-4">
            <detail-item
                class="col-12 col-lg-4"
                :title="$t('personalId.documentIssuedBy')"
                :content="companyPerson.documentIssuedBy"
            />
            <detail-item
                class="col-12 col-lg-4"
                :title="$t('personalId.documentNumber')"
                :content="companyPerson.documentNumber"
            />
            <detail-item
                class="col-12 col-lg-4"
                :title="$t('personalId.documentValidity')"
                :content="new Date(companyPerson.documentValidity).toLocaleDateString()"
            />
            <detail-item
                class="col-12 col-lg-4"
                :title="$t('companyOwnersForm.jobFunction')"
                :content="companyPerson.jobFunction"
            />
            <detail-item
                class="col-12 col-lg-4"
                :title="$t('clientSessions.detail.clientInfo.politician')"
                :content="companyPerson.politician ? $t('app.yes') : $t('app.no')"
            />
        </div>
        <div class="row mb-4">
            <detail-item
                class="col-12 col-lg-4"
                :title="$t('personalId.documentType')"
                :content="$t(`personalIdTypes.${companyPerson.documentType}`)"
            />
            <detail-item
                class="col-12 col-lg-4"
                :title="$t('personalId.secondaryDocumentType')"
                :content="$t(`personalIdTypes.${companyPerson.secondaryDocumentType}`)"
            />
        </div>

        <uploads-list
            :uploads="uploads"
        />
    </div>
</template>
<script>
import UploadsList from './UploadsList.vue'
export default {
    name: 'CompanyPerson',
    components: {
        UploadsList
    },
    props: {
        companyPerson: {
            type: Object,
            required: true
        },
        uploads: {
            type: Array,
            required: true
        }
    }
}
</script>

<template>
    <div class="my-4 container-fluid">
        <component-loader v-if="clientSessionLoading || uploadsLoading" :loading="true"/>
        <div v-else>
            <div class="mb-2">
                <h1 class="mb-0">{{ $t('clientSessions.detail.heading') }} {{ sessionId }}</h1>
                <span
                    :class="{'badge text-white bg-primary': true, 'bg-danger': sessionHasError}"
                >
                    {{ $t(`clientSessions.state.${sessionState}`) }}
                </span>
            </div>
            <b-btn
                class="mb-3"
                variant="secondary"
                size="sm"
                @click.prevent="onBack"
            >
                <b-icon icon="chevron-double-left" /> {{ $t('app.goBack') }}
            </b-btn>
            <div class="row">
                <div class="col-12 col-xl-7 d-flex align-items-center">
                    <b-btn
                        class="mb-2 mb-xl-0"
                        variant="primary"
                        size="sm"
                        @click.prevent="onSendToIvan"
                    >
                        <b-icon class="mr-2" icon="envelope" />{{ $t('clientSessions.sendToIvan') }}
                    </b-btn>
                </div>
                <div class="col-12 col-xl-5">
                    <loader-overlay
                        :loading="savingState"
                    >
                        <session-change-state-form
                            :current-state="sessionState || 'error'"
                            @submit="onChangeSessionState"
                        />
                    </loader-overlay>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-xl-7 mb-4">
                    <div class="bg-white shadow p-4 rounded">
                        <div class="mb-4" v-if="clientSession && clientSession.errorMessage">
                            <div class="alert alert-danger">
                                {{ clientSession.errorMessage }}
                            </div>
                        </div>
                        <b-tabs content-class="mt-4" fill>
                            <b-tab
                                :title="$t('clientSessions.detail.tab.personalData')"
                            >
                                <div class="row mt-4">
                                    <div class="col-12 col-lg-7 mb-4 d-flex flex-column">
                                        <basic-data-client-info
                                            class="rounded border p-4 h-100"
                                            :basic-data="basicData || {}"
                                            :client-type="clientType"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-5 mb-4 d-flex flex-column">
                                        <basic-data-contact-info
                                            class="rounded border p-4 h-100"
                                            :basic-data="basicData || {}"
                                        />
                                    </div>
                                    <div class="col-12 mb-lg-4 d-flex flex-column">
                                        <personal-id
                                            class="rounded border p-4 h-100"
                                            v-if="clientType !== 'PO'"
                                            :personal-id="personalId || {}"
                                            :uploads="uploads || []"
                                        />
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab :title="$t('clientSessions.detail.tab.bankAccounts')">
                                <div class="mt-4">
                                    <h4 class="mb-2">{{ $t('bankAccounts.heading') }}</h4>
                                    <bank-accounts-table
                                        :dataset="bankAccounts"
                                        @show="onShowBankAccount"
                                    />
                                </div>
                            </b-tab>
                            <b-tab :title="$t('clientSessions.detail.tab.legalInfo')">
                                <div class="row mt-4">
                                    <div class="col-12 col-lg-6 mb-4 d-flex flex-column">
                                        <legal-info-money-origin
                                            class="border rounded p-4 h-100"
                                            :legal-info="legalInfo || {}"
                                            :uploads="uploads || []"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-6 mb-4 d-flex flex-column">
                                        <legal-info-reason-and-amount
                                            class="border rounded p-4 h-100"
                                            :uploads="uploads || []"
                                            :legal-info="legalInfo || {}"
                                        />
                                    </div>
                                    <div
                                        class="col-12 mb-4 d-flex flex-column"
                                        v-if="clientType === 'PO'"
                                    >
                                        <legal-info-company-info
                                            class="border rounded p-4 h-100"
                                            :legal-info="legalInfo || {}"
                                            :uploads="uploads || []"
                                        />
                                    </div>
                                    <div class="col-12 mb-4 d-flex flex-column">
                                        <legal-info-other
                                            class="border rounded p-4 h-100"
                                            :legal-info="legalInfo || {}"
                                        />
                                    </div>
                                    <div class="col-12 mb-4 d-flex flex-column">
                                        <legal-info-i-b-and-v-o-p
                                            class="border rounded p-4 h-100"
                                            :legal-info="legalInfo || {}"
                                            :ib="ib || true"
                                        />
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab
                                :title="$t('clientSessions.detail.tab.people')"
                                v-if="companyPeople && Array.isArray(companyPeople) && companyPeople.length > 0"
                            >
                                <div class="mt-4">
                                    <h4 class="mb-2">{{$t('clientSessions.detail.companyPerson.heading')}}</h4>
                                    <company-people-table
                                        :dataset="companyPeople"
                                        @show="onShowCompanyPerson"
                                    />
                                </div>
                            </b-tab>
                            <b-tab
                                :title="$t('clientSessions.detail.tab.realOwners')"
                                v-if="realOwners && Array.isArray(realOwners) && realOwners.length > 0"
                            >
                                <div class="mt-4">
                                    <h4 class="mb-2">{{$t('clientSessions.detail.realOwner.heading')}}</h4>
                                    <real-owner-table
                                        :dataset="realOwners"
                                        @show="onShowRealOwner"
                                    />
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
                <div class="col-12 col-xl-5">
                    <div class="bg-white shadow p-4 rounded">
                        <export-state-table
                            :dataset="exportStates"
                            :loading="exportStatesLoading"
                        />
                    </div>
                </div>
            </div>
            <company-person-modal
                :company-person="companyPerson || {}"
                :uploads="uploads || []"
            />
            <real-owner-modal
                :real-owner="realOwner || {}"
                :statutory-types="statutoryTypes"
                :uploads="uploads || []"
            />
            <bank-account-modal
                :bank-account="bankAccount || {}"
                :uploads="uploads || []"
            />
        </div>
    </div>
</template>

<script>
import ExportStateTable from './Components/ExportStateTable.vue'
import BasicDataClientInfo from './Components/BasicDataClientInfo.vue'
import BasicDataContactInfo from './Components/BasicDataContactInfo.vue'
import PersonalId from './Components/PersonalId.vue'
import LegalInfoMoneyOrigin from './Components/LegalInfoMoneyOrigin.vue'
import LegalInfoCompanyInfo from './Components/LegalInfoCompanyInfo.vue'
import LegalInfoReasonAndAmount from './Components/LegaInfoReasonAndAmount.vue'
import LegalInfoOther from './Components/LegalInfoOther.vue'
import LegalInfoIBAndVOP from './Components/LegalInfoIBAndVOP.vue'
import CompanyPeopleTable from './Components/CompanyPeopleTable.vue'
import CompanyPersonModal from './Components/CompanyPersonModal.vue'
import RealOwnerModal from './Components/RealOwnerModal.vue'
import RealOwnerTable from './Components/RealOwnerTable.vue'
import BankAccountsTable from './Components/BankAccountsTable.vue'
import BankAccountModal from './Components/BankAccountModal.vue'
import SessionChangeStateForm from './Components/SessionChangeStateForm.vue'

export default {
    name: 'ClientSessionsDetail',
    components: {
        ExportStateTable,
        BasicDataClientInfo,
        BasicDataContactInfo,
        PersonalId,
        LegalInfoMoneyOrigin,
        LegalInfoCompanyInfo,
        LegalInfoReasonAndAmount,
        LegalInfoOther,
        LegalInfoIBAndVOP,
        CompanyPeopleTable,
        CompanyPersonModal,
        RealOwnerModal,
        RealOwnerTable,
        BankAccountsTable,
        BankAccountModal,
        SessionChangeStateForm
    },
    props: {
        sessionId: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            bankAccount: null,
            clientSession: null,
            clientSessionLoading: true,
            companyPerson: null,
            realOwner: null,
            exportStatesLoading: false,
            exportStates: [],
            uploads: [],
            uploadsLoading: true,
            savingState: false,
            statutoryTypes: []
        }
    },
    mounted () {
        this.getClientSession()
        this.loadExportStates()
        this.getUploads()
        this.getStatutoryTypes()
    },
    computed: {
        sessionState () {
            return this.clientSession ? this.clientSession.state : 'error'
        },
        sessionHasError () {
            return this.sessionState === 'error'
        },
        clientType () {
            return (this.clientSession && this.clientSession.clientType) ? this.clientSession.clientType : null
        },
        basicData () {
            return (this.clientSession && this.clientSession.basicData) ? this.clientSession.basicData : null
        },
        personalId () {
            return (this.clientSession && this.clientSession.personalId) ? this.clientSession.personalId : null
        },
        bankAccounts () {
            return this.clientSession && this.clientSession.bankAccounts
                ? this.clientSession.bankAccounts.map(item => {
                    return {
                        wholeAccountNumber: `${item.accountNumberPrefix}-${item.accountNumber}/${item.bankCode}`,
                        ...item
                    }
                })
                : []
        },
        legalInfo () {
            return (this.clientSession && this.clientSession.legalInfo) ? this.clientSession.legalInfo : null
        },
        ib () {
            return (this.clientSession && this.clientSession.activateIB) ? this.clientSession.activateIB : null
        },
        companyPeople () {
            return (this.clientSession && this.clientSession.companyPeople) ? this.clientSession.companyPeople : []
        },
        realOwners () {
            return (this.clientSession && this.clientSession.realOwners) ? this.clientSession.realOwners : []
        }
    },
    methods: {
        getClientSession () {
            this.clientSessionLoading = true
            this.$api.sessions.read(this.sessionId)
                .then((response) => {
                    this.clientSession = response.data
                    this.clientSessionLoading = false
                })
                .catch((error) => {
                    console.error(error)
                })
        },
        getStatutoryTypes () {
            this.$api.enums.read('statutory-types')
                .then(response => {
                    this.statutoryTypes = response.data
                })
                .catch(error => {
                    console.error(error)
                })
        },
        onShowCompanyPerson (companyPerson) {
            this.companyPerson = companyPerson
            this.$bvModal.show('companyPersonModal')
        },
        onShowRealOwner (realOwner) {
            this.realOwner = realOwner
            this.$bvModal.show('realOwnerModal')
        },
        onShowBankAccount (bankAccount) {
            this.bankAccount = bankAccount
            this.$bvModal.show('bankAccountModal')
        },
        async loadExportStates () {
            this.exportStatesLoading = true
            try {
                const response = await this.$api.exportStates.read(null, { clientSessionId: this.sessionId, processName: 'CreateClientProcess' })
                this.exportStates = response.data
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('errors.cannotLoadExportStates'))
            } finally {
                this.$nextTick(() => {
                    this.exportStatesLoading = false
                })
            }
        },
        getUploads () {
            this.uploadsLoading = true
            this.$api.sessions.getAllFiles(this.sessionId)
                .then((response) => {
                    this.uploads = response.data
                    this.uploadsLoading = false
                })
                .catch((error) => {
                    console.error(error)
                    this.$notify.error(this.$t('errors.cannotLoadUploads'))
                })
        },
        onBack () {
            this.$router.push({ name: 'AdminClientSessions' })
        },
        onChangeSessionState (data) {
            this.savingState = true
            this.$api.sessions.changeState(this.sessionId, data)
                .catch((error) => {
                    console.error(error)
                    this.$notify.error(this.$t('errors.cannotUpdateSessionState'))
                })
                .finally(() => {
                    this.savingState = false
                    this.getClientSession()
                })
        },
        onSendToIvan () {
            this.$api.sessions.exportToIvan(this.sessionId)
                .then(response => {
                    const clientSession = response.data
                    if (clientSession.state === 'error') {
                        this.$notify.error(this.$t('clientSessions.sendToIvanError'))
                    }
                })
                .catch(error => {
                    this.$notify.error(this.$t('clientSessions.sendToIvanError'))
                    console.error(error)
                })
                .finally(() => {
                    this.getClientSession()
                    this.loadExportStates()
                })
        }
    }
}
</script>
